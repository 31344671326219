import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';

import { SignUp } from '@templates';
import { Logo, Text } from '@components';
import { main } from '@themes';

import { Form } from './Form';
import * as S from './nameConfirmation.styles';

export const NameConfirmation = () => {
  const token = Cookies.get('tokenClient');
  const refreshToken = Cookies.get('refreshtokenClient');

  const router = useRouter();

  //@ts-ignore
  const { userData } = useSelector((state) => state.userReducer);

  useEffect(() => {
    if (!!userData && userData?.social_name !== null) router.push('/home');
  }, [userData?.social_name]);

  if (!userData || userData?.social_name !== null) return <></>;

  return (
    <SignUp titlePage="Confirmar Nome">
      <S.Wrapper>
        <div className="mobile-only">
          <Logo size="150px" color={main.colorPallete.white} />
        </div>

        <S.TitleSection>
          {
            //@ts-ignore
            <Text
              as="h1"
              size="1.6rem"
              dsize="1.6rem"
              color={main.colorPallete.base[400]}
              weight={600}
              height="20px"
              dheight="normal">
              Atualização necessária
            </Text>
          }
          <div className="titlesection__step">
            {
              //@ts-ignore
              <Text
                as="h4"
                size="2rem"
                dsize="2rem"
                color={main.colorSystem.baseColor700}
                weight="bold"
                height="25px"
                dheight="normal">
                Nome em certificado
              </Text>
            }
          </div>
        </S.TitleSection>
        <S.TextContainer>
          {
            //@ts-ignore
            <Text
              as="p"
              size="1.5rem"
              dsize="1.5rem"
              color={main.colorPallete.base[350]}
              weight="400"
              dheight="normal">
              Olá, estudante! Estamos implementando os certificados no
              meuSucesso. A fim de que possa emitir o seu ao concluir cursos
              específicos precisamos confirmar seu nome completo.
            </Text>
          }
          {
            //@ts-ignore
            <Text
              as="p"
              size="1.5rem"
              dsize="1.5rem"
              color={main.colorPallete.base[350]}
              weight="400"
              dheight="normal">
              Atualmente, seu nome completo{' '}
              <S.FullName>“{userData?.name}”</S.FullName> será exibido em seus
              certificados.
            </Text>
          }
          {
            //@ts-ignore
            <Text
              as="p"
              size="1.5rem"
              dsize="1.5rem"
              color={main.colorPallete.base[350]}
              weight="400"
              dheight="normal">
              Certifique-se de que esteja correto ou altere-o na sessão abaixo.
            </Text>
          }
          {
            //@ts-ignore
            <Text
              as="p"
              size="1rem"
              dsize="1rem"
              color={main.colorPallete.base[350]}
              weight="400"
              dheight="normal">
              ATENÇÃO: Esta informação não poderá ser alterada! Caso mais tarde
              deseje modificar o nome exibido, entre em contato com nossa
              central de atendimento.
            </Text>
          }
        </S.TextContainer>
        <S.FormContainer>
          <Form />
        </S.FormContainer>
      </S.Wrapper>
    </SignUp>
  );
};
